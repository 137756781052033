//import styles from "./styles.module.css";
import useMostViewed from '@/hooks/useMostViewed';
import MostViewedItem from './components/MostViewedItem';
import { ParentGridMost } from "./styles";
import Separator from "@/shared/Separator";


const MostViewed = (props) => {
    const {
        title = 'Lo más visto por suscriptores',
        typeViewed = 'lilanews',
        content } = props;
    const mostViewed = content;
    /* const { mostViewed } = useMostViewed(); */
    const marfeelTitle = title ? "ContainerLoMasVistoSus-"+title?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, '-')
    .toLowerCase() : "ContainerLoMasVistoSus"; 
    return (
        <>
            {mostViewed &&
               <div>
                    <Separator title={title} />
                    <ParentGridMost data-mrf-recirculation={`${marfeelTitle}`}>
                        {mostViewed.map((item, index) => {
                            return (
                                <li className="box-items" key={index}>
                                    <MostViewedItem
                                        typeViewed={typeViewed}
                                        number={index + 1}
                                        title={item.title}
                                        subtitle={item.subtitle ?? item.sections?.[0]?.name}
                                        url={item.url}
                                        related={item.related}
                                        authors={item.authors}
                                    />
                                </li>
                            )
                        })}
                    </ParentGridMost>
                    </div>
            }
        </>
    );
};

export default MostViewed;