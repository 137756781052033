import ContentImage from "@/shared/ContentImage";
import { getImageByClippings } from "@/helpers/getImageByClippings";
import { ItemMostViewed } from "./styles";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import { Fragment } from "react";
const MostViewedItem = (props) => {

    const {
        authors = [],
        title = '',
        related = {},
        url = '',
        subtitle = '',
        number = 0,
        typeViewed = '',
    } = props;

    const images = (related.images && related.images[0] && related.images[0].clippings)
        ? related.images[0].clippings
        : [];

    const source = { desktop: ["square_sm"], mobile: ["square_sm"] };
    const data = getImageByClippings(source, images);

    return (
        <ItemMostViewed>
            <div className="box-container">
                <div className="mt">
                    <span>
                        {authors?.length > 0 && 
                            authors.map((author, index) => 
                                <Fragment key={`author${index}`}>{author.name}{index + 1 < author.length && ", "}</Fragment>
                        )}
                    </span>
                    <h2>{title}</h2>
                    <p> {subtitle}</p>
                </div>
                <picture>
                    <ContentImage data={data} alt={title} />
                    <div className="number"><span>{(number) && number}</span></div>
                </picture>
            </div>
            <div>
                <span className="number">{(number) && number}</span>
            </div>
            <div>
            </div>
            <LinkAbsolute href={url} target="_self" aria-label={title} />
        </ItemMostViewed>
    );
};

export default MostViewedItem;