


import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";


export const ParentGridMost= styled.ul `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  ${breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100% ;
    li{
      &::before {
        display:none;
      }
    }
  }
  li{
      margin:0 15px;
      border-bottom: 1px solid #E0E0E0;
      position: relative;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        border-bottom: 1px solid ${ColorDark.greySeparator};
        }
      }
      ${breakpoints.phone} {
        margin:0 ;
      }
      &:nth-child(n+6), &:nth-child(n+5) {
        border-bottom: 0px;
      }
      &:nth-child(odd){
        margin-left:0;
      }
      &:nth-child(even){
        margin-right:0;
      }
      &:nth-child(odd)::before{
            content: "";
            position: absolute;
            right: -17px;
            top: 0px;
            width: 1px;
            height: 109%;
            background-color: #E0E0E0;
        }
        
  }
 
`
